import PropTypes from "prop-types";
import { useMemo } from "react";
import styled from "styled-components";
import {
  ActionChip,
  PlusIcon,
  Fonts,
  ColorPalette,
  CheckIcon,
  HyperLink,
  LinkStyles,
} from "yuka";

import { useCompany } from "./hooks";

import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import useWrite from "../api/useWrite";
import { ActionButton } from "../hdYuka";
import usePortfoliosContainingCompany from "../Portfolios/hooks/usePortfoliosContainingCompany";
import { ROUTING_PATH } from "../routes/constants";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const StyledDropdownContainer = styled.div`
  margin: -16px 0;
  min-width: 360px;

  > :first-child {
    overflow-y: auto;
    max-height: 400px;
    margin-top: 12px;
  }
`;

const StyledHorizontalRule = styled.hr`
  border: none;
  border-bottom: 1px solid ${ColorPalette.white15};
  margin-top: 12px;
`;

const StyledPortfolioListItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  padding: 8px 12px;
  box-sizing: border-box;
`;

const StyledCreatePortfolioButton = styled(ActionButton)`
  margin: 12px;
`;

const AddToPortfolioDropdown = ({ source, openCreatePortfolioModal }) => {
  const [company, companyIsLoading] = useCompany();
  const addCompanyToPortfolio = useWrite(
    API_ENDPOINTS.PORTFOLIO_COMPANIES(),
    false,
    {
      message: `${company?.name} added to portfolio`,
    }
  );
  const portfolioQuery = useFetch(API_ENDPOINTS.PORTFOLIOS());

  const portfolios = useMemo(() => {
    return portfolioQuery.isSuccess && !portfolioQuery.isRefetching
      ? portfolioQuery.cleanedData.data
      : [];
  }, [
    portfolioQuery.isSuccess,
    portfolioQuery.isRefetching,
    portfolioQuery.cleanedData,
  ]);
  const [portfoliosWithThisCompany, portfolioContainingCompanyIsLoading] =
    usePortfoliosContainingCompany(company?.zb_id);

  if (
    companyIsLoading ||
    portfolioQuery.isLoading ||
    portfolioContainingCompanyIsLoading
  ) {
    return (
      <StyledDropdownContainer>
        <StyledPortfolioListItem>
          <Fonts.Body1theme30>Loading...</Fonts.Body1theme30>
        </StyledPortfolioListItem>
      </StyledDropdownContainer>
    );
  }

  return (
    <StyledDropdownContainer>
      <div>
        {portfolios.map((portfolio) => {
          const alreadyInPortfolio = Boolean(
            portfoliosWithThisCompany.find(
              (portfolioCompany) =>
                portfolioCompany.portfolio[1] === portfolio.apiId
            )
          );
          return (
            <StyledPortfolioListItem key={portfolio.apiId}>
              <HyperLink
                linkStyle={LinkStyles.INVISIBLE}
                url={ROUTING_PATH.PORTFOLIO(portfolio.apiId)}
              >
                {alreadyInPortfolio ? (
                  <Fonts.Body1theme30>{portfolio.name}</Fonts.Body1theme30>
                ) : (
                  <Fonts.Body1theme80>{portfolio.name}</Fonts.Body1theme80>
                )}
              </HyperLink>
              <ActionChip
                disabled={alreadyInPortfolio}
                leadingIcon={alreadyInPortfolio ? CheckIcon : PlusIcon}
                onClick={() => {
                  addCompanyToPortfolio.mutate({
                    portfolio: portfolio.apiId,
                    company: company.zb_id,
                  });
                  MixpanelEvents.addCompanyToPortfolio(
                    source,
                    portfolio.apiId,
                    company.name
                  );
                }}
                text={alreadyInPortfolio ? "Added" : "Add"}
              />
            </StyledPortfolioListItem>
          );
        })}
      </div>
      <StyledHorizontalRule />
      <StyledCreatePortfolioButton
        onClick={openCreatePortfolioModal}
        icon={PlusIcon}
      >
        New portfolio
      </StyledCreatePortfolioButton>
    </StyledDropdownContainer>
  );
};

AddToPortfolioDropdown.propTypes = {
  source: PropTypes.string,
  openCreatePortfolioModal: PropTypes.func.isRequired,
};

export default AddToPortfolioDropdown;
