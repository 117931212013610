import PropTypes from "prop-types";
import { useMemo } from "react";
import styled, { css } from "styled-components";
import {
  ArrowUpIcon,
  ColorPalette,
  FontColors,
  YukaThemeProvider,
  ArrowDownIcon,
  Fonts,
} from "yuka";

import { useLastWeekPortfolioValue, usePortfolioValue } from "./hooks";

import { percentFormat } from "../utils/displayFormatUtils";

const StyledCounter = styled.div`
  ${FontColors.theme80}

  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 16px;
  border-radius: 8px;
  padding: 0 8px;
  height: 16px;
  ${(props) => {
    if (props.$percentChange) {
      if (props.$percentChange > 0) {
        return css`
          background: ${props.theme.colors.green};
        `;
      }
      if (props.$percentChange < 0) {
        return css`
          background: ${props.theme.colors.red};
        `;
      }
    }
    return css`
      background: ${ColorPalette.white15};
    `;
  }}
`;

const PortfolioTabContent = ({ portfolioId, name }) => {
  const portfolioValue = usePortfolioValue(portfolioId);
  const lastWeekPortfolioValue = useLastWeekPortfolioValue(portfolioId);

  const percentChange = useMemo(() => {
    if (portfolioValue === null || lastWeekPortfolioValue === null) {
      return null;
    }
    if (lastWeekPortfolioValue === 0) {
      return null; // Infinity percent
    }
    return (portfolioValue - lastWeekPortfolioValue) / lastWeekPortfolioValue;
  }, [portfolioValue, lastWeekPortfolioValue]);

  const ArrowIcon = percentChange > 0 ? ArrowUpIcon : ArrowDownIcon;

  const percentDisplay = useMemo(() => {
    if (percentChange) {
      return (
        <Fonts.Caption2theme80>
          {percentFormat(Math.abs(percentChange) * 100, 2)}
        </Fonts.Caption2theme80>
      );
    }
    return (
      <YukaThemeProvider theme={{ mode: "dark" }}>
        <Fonts.Caption2theme30>--</Fonts.Caption2theme30>
      </YukaThemeProvider>
    );
  }, [percentChange]);

  return (
    <>
      {name}
      <YukaThemeProvider theme={{ mode: "light" }}>
        <StyledCounter $percentChange={percentChange}>
          {percentChange && <ArrowIcon color={ColorPalette.black50} />}
          {percentDisplay}
        </StyledCounter>
      </YukaThemeProvider>
    </>
  );
};

PortfolioTabContent.propTypes = {
  portfolioId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default PortfolioTabContent;
